import React from 'react'
import classes from './ButtonMenu.module.css'

const ButtonMenu = props => {
  const cls = [classes.i]
  if (props.menuState === 'open') {
    cls.push(classes.open)
  }
  
  return (
    <div className={cls.join(' ')}
         onClick={props.onButtonMenuHandler}
    >
      <i className="fas fa-bars fa-2x"></i>
    </div>
  )
}

export default ButtonMenu