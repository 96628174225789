import React, {Component} from 'react'
import classes from './MainPage.module.css'
import {NavLink} from "react-router-dom";
import MediaQuery from "react-responsive";

export default class MainPage extends Component {

  render() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.Mainpage_wrapper_background}>
            <div className={classes.Mainpage_div_for_img_plane}>
              <div className={classes.Component_wrapper}>
                <img className={classes.Mainpage_img_logo} src='/img/main_page/logo.svg'/>
                <h1 className={classes.Mainpage_h1}>Создавайте простые тесты быстро и бесплатно</h1>
                <div className={classes.Mainpage_advatages}>
                  <ul>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info1.svg'/>
                      <p className={classes.Mainpage_advatages_p}>Каждый тест содержит вопрос и 4 варианта ответа</p>
                    </li>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info2.svg'/>
                      <p className={classes.Mainpage_advatages_p}>Правильным может быть только 1 вариант ответа</p></li>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info3.svg'/>
                      <p className={classes.Mainpage_advatages_p}>
                        Можно создать неограниченное количество тестов после регистрации
                      </p></li>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info4.svg'/>
                      <p className={classes.Mainpage_advatages_p}>По желанию добавляйте иллюстрации к вопросам</p></li>
                  </ul>
                </div>
                <div className={classes.Mainpage_div_for_buttons_wrapper}>
                  <div className={classes.Mainpage_div_for_buttons}>
                    <NavLink to={'quiz-create'}>
                      <button className={classes.Mainpage_button_create_test}>
                        СОЗДАТЬ ТЕСТ
                      </button>
                    </NavLink>
                    <NavLink to={'quiz/-NHIglEiPyQny2WNZ8cV'}>
                      <button className={classes.Mainpage_button_example}>
                        ОБРАЗЕЦ
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className={classes.Mainpage_div_for_order_wrapper}>
                  <div className={classes.Mainpage_div_for_order}>
                    <img className={classes.Mainpage_img_order} src='/img/main_page/alertcircle.svg' alt='alertcircle'/>
                    <p>Закажите такое приложение или дополнительный функционал</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Mainpage_wrapper_background_mobile}>
            <div className={classes.Mainpage_div_for_img_plane}>
              <div className={classes.Component_wrapper_mobile}>
                <img className={classes.Mainpage_img_logo} src='/img/main_page/logo.svg'/>
                <h1 className={classes.Mainpage_h1_mobile}>Создавайте простые тесты <br /> быстро и бесплатно</h1>
                <div className={classes.Mainpage_advatages_mobile}>
                  <ul>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info1.svg'/>
                      <p className={classes.Mainpage_advatages_p_mobile}>Каждый тест содержит вопрос и 4 варианта ответа</p>
                    </li>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info2.svg'/>
                      <p className={classes.Mainpage_advatages_p_mobile}>Правильным может быть только 1 вариант ответа</p></li>
                  </ul>
                </div>

                <div className={classes.Mainpage_advatages_mobile}>
                  <ul>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info3.svg'/>
                      <p className={classes.Mainpage_advatages_p_mobile}>
                        Можно создать неограниченное количество тестов после регистрации
                      </p></li>
                    <li>
                      <img className={classes.Mainpage_advatages_img} src='/img/main_page/info4.svg'/>
                      <p className={classes.Mainpage_advatages_p_mobile}>По желанию добавляйте иллюстрации к вопросам</p></li>
                  </ul>
                </div>
                {/*<div className={classes.Mainpage_div_for_buttons_wrapper_mobile}>*/}
                  <div className={classes.Mainpage_div_for_buttons_mobile}>
                    <NavLink to={'quiz-create'}>
                      <button className={classes.Mainpage_button_create_test_mobile}>
                        СОЗДАТЬ ТЕСТ
                      </button>
                    </NavLink>
                    <NavLink to={'quiz/-NHIglEiPyQny2WNZ8cV'}>
                      <button className={classes.Mainpage_button_example}>
                        ОБРАЗЕЦ
                      </button>
                    </NavLink>
                  </div>
                {/*</div>*/}
                {/*<div className={classes.Mainpage_div_for_order_wrapper}>*/}
                  <div className={classes.Mainpage_div_for_order_mobile}>
                    <img className={classes.Mainpage_img_order_mobile} src='/img/main_page/alertcircle.svg' alt='alertcircle'/>
                    <p>Закажите такое приложение или <br /> дополнительный функционал</p>
                  </div>
                {/*</div>*/}
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    )
  }
}