import React from 'react'
import classes from './Backdrop.module.css'

const Backdrop = props => {
  const cls = [classes.Backdrop]
  
  return (
    <div className={cls.join(' ')}
         onClick={props.onButtonMenuCloseHandler}
    >
    </div>
  )
}

export default Backdrop