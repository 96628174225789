import React, {Component} from 'react'
import classes from '../CommonContainer.module.css'
import Loader from '../../Components/UI/Loader/Loader'
import axios from '../../axios/axios-quiz'
// import axios from 'axios';
import {NavLink} from "react-router-dom";
import MediaQuery from "react-responsive";
// const cors = require('cors')({ origin: true });


//const quizList = ['Тест 1', 'Тест 2', 'Тест 3']


export default class QuizList extends Component {
  state = {
    quizes: [],
    loading: true,
    showDeleteMessageButton: true,
    showDeleteMessage: false,
    deleteButtonsIds: '',
    quizIdToRemove: '',
  }

  async componentDidMount() {
    await this.getQuizList()
  }

  renderQuizList() {
    const quizIdToRemove = this.state.quizIdToRemove
    const quizAuthUID = localStorage.getItem('quizAuthUID')
    return this.state.quizes.map((quiz, index) => {
      return (
        <li
          key={quiz.id}
          onClick={this.quizListEventTargetIdHandler}
        >

          <>
            <MediaQuery minWidth={600}>
              <div className={classes.Container_li_div_wrapper}>
                <NavLink to={'/quiz/' + quiz.id} className={classes.Container_li_navlink}>
                  {quiz.name}
                </NavLink>
                {this.state.showDeleteMessageButton
                  ?
                  <button
                    className={classes.Container_delete_button}
                    id={quiz.id}
                    onClick={this.quizListShowDeleteMessage}>
                    Удалить
                  </button>
                  : null}
                {this.state.showDeleteMessage && quiz.id === this.state.quizIdToRemove
                  ? <>
                    {/*<span className={classes.Container_delete_message}>
              Подтвердите:
              </span>*/}
                    <button
                      className={classes.Container_delete_button}
                      id={quiz.id}
                      // onClick={this.quizListDeleteHandler}
                      onClick={this.quizListDeleteOnClickHandler}
                    >
                      Удалить
                    </button>
                    <button
                      className={classes.Container_delete_button}
                      onClick={this.quizListHideDeleteMessage}
                    >
                      Отмена
                    </button>
                  </>
                  : null
                }
              </div>
            </MediaQuery>

            <MediaQuery maxWidth={599}>
              <div className={classes.Container_mobile_li_div_wrapper}>
                <NavLink to={'/quiz/' + quiz.id} className={classes.Container_mobile_li_navlink}>
                  {quiz.name}
                </NavLink>
                <div className={classes.Container_mobile_div_delete_wrapper}>
                  {this.state.showDeleteMessageButton
                    ?
                    <button
                      className={classes.Container_mobile_delete_button}
                      id={quiz.id}
                      onClick={this.quizListShowDeleteMessage}>
                      Удалить
                    </button>
                    : null}

                {this.state.showDeleteMessage && quiz.id === this.state.quizIdToRemove
                  ? <>
                    {/*<div className={classes.Container_mobile_div_delete_wrapper}>*/}
              <span className={classes.Container_delete_message}>
              Подтвердите:
              </span>
                      <button
                        className={classes.Container_delete_button}
                        id={quiz.id}
                        // onClick={this.quizListDeleteHandler}
                        onClick={this.quizListDeleteOnClickHandler}
                      >
                        Удалить
                      </button>
                      <button
                        className={classes.Container_delete_button}
                        onClick={this.quizListHideDeleteMessage}
                      >
                        Отмена
                      </button>
                    {/*</div>*/}
                  </>
                  : null
                }
                </div>
              </div>
            </MediaQuery>


          </>
        </li>
      )
    })
  }

  quizListEventTargetIdHandler = (event) => {
    const quizIdToRemove = event.target.id

    return (
      this.setState({
        quizIdToRemove,
      })
    )

  }

  quizListShowDeleteMessage = (event) => {
    const quizIdToRemove = event.target.id
    // window.setTimeout(() => console.log('this.state=', this.state), 150)
    return this.state.quizes.forEach((quiz, index) => {
      return (
        quiz.id === quizIdToRemove
          ? this.setState({
            showDeleteMessage: !this.state.showDeleteMessage,
            showDeleteMessageButton: !this.state.showDeleteMessageButton,
            // quizIdToRemove,
          })
          : null
      )
    })
  }

  quizListHideDeleteMessage = () => {
    this.setState({
      showDeleteMessage: !this.state.showDeleteMessage,
      showDeleteMessageButton: !this.state.showDeleteMessageButton,
      quizIdToRemove: '',
    })
  }

  quizListDeleteOnClickHandler = event => {
    const quizIdToRemove = event.target.id
    return this.state.quizes.map((quiz, index) => {
      return (
        quiz.id === quizIdToRemove
          ? this.quizListDeleteHandler(event, quizIdToRemove)
          // quizIdToRemove = event.target.id
          // console.log('OK, index , event.target.key', index, event.target.id)
          :
          console.log('error')
          // console.log('NE OK, index , event.target.key', index, event.target.id)
      )
    })


  }

  // Удаление теста с сервера
  quizListDeleteHandler = async (event, quizIdToRemove) => {
    event.preventDefault()
    const quizAuthUID = this.state.quizAuthUID
    const quizAuthIdToken = this.state.quizAuthIdToken
    this.setState({
      loading: true,
    })
    try {
      const response = await axios.delete(`/quizes/${quizAuthUID}/${quizIdToRemove}.json?auth=${quizAuthIdToken}`, this.state.quiz)
      // const response = await axios.delete(`/quizes/qZABEkzDTDVWnqpr7KToHrwc0W92/-NGtjtunAIMeHR4Zti_n.json?auth=${quizAuthIdToken}`)
      // console.log('Удален тест= ', response.data)
      // console.log('Удален тест= ', this.state)
      await this.getQuizList()
      window.setTimeout(() => this.renderQuizList, 100)
      this.setState({
        loading: false,
        showDeleteMessage: !this.state.showDeleteMessage,
        showDeleteMessageButton: !this.state.showDeleteMessageButton,
        // showDeleteMessageButton: true,
      })
    } catch (error) {
      // console.log(error)
    }
  }

  async getQuizList() {
    const quizAuthUID = localStorage.getItem('quizAuthUID')
    const quizAuthIdToken = localStorage.getItem('quizAuthIdToken')
    this.setState({
      quizAuthUID, quizAuthIdToken
    })

    try {
      const response = await axios.get(`/quizes/${quizAuthUID}.json?auth=${quizAuthIdToken}`)
      const quizes = []
      Object.keys(response.data).forEach((key, index) => {
        Object.values(response.data)[index].length > 1
          ?
          quizes.push({
            id: key,
            name: Object.values(response.data)[index][Object.values(response.data)[0].length - 1].testName
          })
          :
          // console.log('nameName:', Object.values(response.data)[index][0].testName)
          quizes.push({
            id: key,
            name: Object.values(response.data)[index][0].testName
          })

        // quizes.push({
        //   id: key,
        // name: `Тест № ${index + 1}`,
        // name: Object.values(response.data)[index][Object.values(response.data)[0][0]].testName

        // name: Object.values(response.data)[index].length === 1
        // ? Object.values(response.data)[index][Object.values(response.data)[0].length].testName
        // : Object.values(response.data)[index][Object.values(response.data)[0].length - 1].testName

        // })
        // console.log('response.data=', response.data)
        // console.log('(response.data)[index]=', Object.values(response.data)[index])
        // console.log('name:', Object.values(response.data)[index].length)
      })

      // window.setTimeout(() => console.log (Object.values(response.data)[0][0].testName), 100)

      this.setState({
        quizes,
        loading: false
      })
    } catch (error) {
      // console.log(error)
      this.setState({
        loading: false
      })
    }
  }

  /*
    // Получение списка тестов с сервера
    async componentDidMount() {
      const quizAuthUID = localStorage.getItem('quizAuthUID')
      const quizAuthIdToken = localStorage.getItem('quizAuthIdToken')
      this.setState({
        quizAuthUID, quizAuthIdToken
      })

      try {
        const response = await axios.get(`/quizes/${quizAuthUID}.json?auth=${quizAuthIdToken}`)
        const quizes = []
        Object.keys(response.data).forEach((key, index) => {
          Object.values(response.data)[index].length > 1
            ?
            quizes.push({
              id: key,
              name: Object.values(response.data)[index][Object.values(response.data)[0].length - 1].testName
            })
            :
            // console.log('nameName:', Object.values(response.data)[index][0].testName)
            quizes.push({
              id: key,
              name: Object.values(response.data)[index][0].testName
            })

          // quizes.push({
          //   id: key,
          // name: `Тест № ${index + 1}`,
          // name: Object.values(response.data)[index][Object.values(response.data)[0][0]].testName

          // name: Object.values(response.data)[index].length === 1
          // ? Object.values(response.data)[index][Object.values(response.data)[0].length].testName
          // : Object.values(response.data)[index][Object.values(response.data)[0].length - 1].testName

          // })
          // console.log('response.data=', response.data)
          // console.log('(response.data)[index]=', Object.values(response.data)[index])
          console.log('name:', Object.values(response.data)[index].length)
        })

        // window.setTimeout(() => console.log (Object.values(response.data)[0][0].testName), 100)

        this.setState({
          quizes,
          loading: false
        })
      } catch (error) {
        console.log(error)
        this.setState({
          loading: false
        })
      }
    }
    */

  render() {
    return (
      <>

        <MediaQuery minWidth={600}>
          <div className={classes.Component_wrapper}>
            <div className={classes.Component_div_for_image_upper}>
              <img className={classes.Component_image_upper} src='/img/todo_list.png'
                   alt='man_monitor_background' width='241' height='296'/>
            </div>
            <div className={classes.Container}>
              <div className={classes.Container_h1_wrapper}>
                <h1>Список тестов</h1>
              </div>

              {
                this.state.loading
                  ? <Loader/>
                  : this.state.quizes.length !== 0
                  ?
                  <ul>
                    {this.renderQuizList()}
                  </ul>
                  :
                  <p>Не удалось загрузить список тестов. <br/>Возможно, Вы еще не создали ни одного теста.</p>

              }

            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Component_wrapper_mobile}>
            <div className={classes.Component_div_for_image_upper_mobile}>
              <img className={classes.Component_image_upper_mobile} src='/img/todo_list.png'
                   alt='man_monitor_background' width='120' height='220'/>
            </div>
            <div className={classes.Container_mobile}>
              {/*<div className={classes.QuizList_h1_wrapper}>*/}
              <h1 className={classes.Container_mobile_h1_for_QuizList}>Список тестов</h1>
              {/*</div>*/}

              {
                this.state.loading
                  ? <Loader/>
                  : this.state.quizes.length !== 0
                  ?
                  <ul>
                    {this.renderQuizList()}
                  </ul>
                  :
                  <p>Не удалось загрузить список тестов. <br/>Возможно, Вы еще не создали ни одного теста.</p>

              }

            </div>
          </div>
        </MediaQuery>

      </>
    )
  }
}