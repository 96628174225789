import React from 'react'
import classes from './ButtonBlue.module.css'

const ButtonBlue = props => {
  return (
    <button
      className={classes.ButtonBlue}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export default ButtonBlue