import React, {Component} from 'react'
import classes from './QuizCreator.module.css'
import ButtonBlue from "../../Components/UI/Button/ButtonBlue/ButtonBlue";
import ButtonGreen from "../../Components/UI/Button/ButtonGreen/ButtonGreen";
import Input from "../../Components/UI/Input/Input";
import {createControl, validate, validateForm} from "../../form/formFramework"
import Select from "../../Components/UI/Select/Select";
import axios from "../../axios/axios-quiz";
import {NavLink} from "react-router-dom";
// import FileLoader from "../../Components/UI/FileLoader/FileLoader";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../firebase/firebase";
import Loader from "../../Components/UI/Loader/Loader";
import MediaQuery from "react-responsive";

function createOptionControl(number) {
  return createControl(
    {
      label: `Вариант ${number}`,
      errorMessage: 'Значение не может быть пустым',
      id: number,
    },
    {
      required: true,
    }
  )
}

function createTestName() {
  return {
    name: createControl(
      {
        label: '',
        errorMessage: 'Значение не может быть пустым'
      },
      {
        required: true,
      },
      'Введите название теста'
    )
  }
}

function createFormControls() {
  return {
    question: createControl(
      {
        label: 'Введите вопрос',
        errorMessage: 'Значение не может быть пустым'
      },
      {
        required: true,
      }
    ),
    option1: createOptionControl(1),
    option2: createOptionControl(2),
    option3: createOptionControl(3),
    option4: createOptionControl(4),
  }
}

export default class QuizCreator extends Component {
  state = {
    quiz: [],
    rightAnswerId: 1,
    isFormValid: false,
    testName: createTestName(),
    formControls: createFormControls(),
    newTestAdded: false,
    responseDataName: '',
    quizAuthUID: '',
    isLoggedIn: '',

    //FileLoaderState
    file: null,
    preview: '',
    url: '',
    progress: 0,
    showLoader: false,
  }

  componentDidMount() {
    let quizAuthExpiresIn = window.localStorage.getItem('quizAuthExpiresIn')
    quizAuthExpiresIn > Date.now()
      ? this.setState({
        isLoggedIn: true
      })
      : this.setState({
        isLoggedIn: false
      })

    this.setState({
      quizAuthUID: localStorage.getItem('quizAuthUID'),
      quizAuthIdToken: localStorage.getItem('quizAuthIdToken'),
      quizAuthExpiresIn: localStorage.getItem('quizAuthExpiresIn')
    })
  }

  // ======== НАЧАЛО блока FileLoader ========

  // Image Preview Handler
  handleImagePreview = (event) => {
    let image_as_base64 = URL.createObjectURL(event.target.files[0])
    let image_as_files = event.target.files[0];

    this.setState({
      file: image_as_files,
      preview: image_as_base64,
    })
    // window.setTimeout(() => console.log('this.state=', this.state), 200)
    // window.setTimeout(() => console.log('this.state.file.name=', this.state.file.name), 210)
  }

  //Загрузка файла в google firebase  https://firebase.google.com/docs/storage/web/upload-files#web-version-9

  fileUploadFirebase = () => {
    const quizAuthUID = this.state.quizAuthUID
    const quizAuthIdToken = this.state.quizAuthIdToken

    const {file} = this.state
    const imgRef = ref(storage, `img/${quizAuthUID}/${file.name}`)

    // Simple file upload:
    /*uploadBytes(imgRef, file).then((snapshot) => {
      console.log('Uploaded a blob or file!')
    })*/

    // Improved file upload:
    // Create the file metadata
    const metadata = {
      contentType: 'image/jpeg',
      // customMetadata: {
      //   'userId': quizAuthUID,
      // }
    }
    // Upload file and metadata
    const uploadTask = uploadBytesResumable(imgRef, file, metadata)
// Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // console.log('Upload is ' + progress + '% done')
        this.setState({
          progress: progress,
          showLoader: true,
        })
        switch (snapshot.state) {
          case 'paused':
            // console.log('Upload is paused')
            break
          case 'running':
            // console.log('Upload is running')
            break
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break
          case 'storage/canceled':
            // User canceled the upload
            break
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL)
          // window.setTimeout(() => console.log('this.state=', this.state), 200)
          this.setState({
            url: downloadURL,
          })
        })
      })
  }

// ======== ОКОНЧАНИЕ блока FileLoader ========


  submitHandler = event => {
    event.preventDefault()
  }

  // ======== Обработчик "Добавить вопрос" ========
  addQuestionHandler = event => {
    event.preventDefault()

    const quiz = this.state.quiz.concat()
    const index = quiz.length + 1

    const testName = this.state.testName.name

    const {question, option1, option2, option3, option4} = this.state.formControls

    const url = this.state.url

    const questionItem = {
      testName: testName.value,
      question: question.value,
      id: index,
      rightAnswerId: this.state.rightAnswerId,
      answers: [
        {text: option1.value, id: option1.id},
        {text: option2.value, id: option2.id},
        {text: option3.value, id: option3.id},
        {text: option4.value, id: option4.id}
      ],
      url: url,
    }
    quiz.push(questionItem)

    this.setState({
      quiz: quiz,
      rightAnswerId: 1,
      isFormValid: false,
      // testName: createTestName(),
      formControls: createFormControls(),

      //FileLoaderState
      file: null,
      preview: '',
      url: '',
      progress: 0,
      showLoader: false,
    })

    // window.setTimeout(() => console.log('this.state = ', this.state), 100)


  }

  createQuizHandlerDemo = event => {
    event.preventDefault()

    // console.log(this.state.quiz)
  }

  // ===== Обработчик Отправка теста на сервер =====
  createQuizHandler = async event => {
    event.preventDefault()
    const quizAuthUID = this.state.quizAuthUID
    const quizAuthIdToken = this.state.quizAuthIdToken
    try {
      // const response = await axios.post('/quizes.json', this.state.quiz)
      const response = await axios.post(`/quizes/${quizAuthUID}.json?auth=${quizAuthIdToken}`, this.state.quiz)

      // const response = await axios.post(`/quizes/${quizAuthUID}/`, this.state.quiz)
      // console.log('Отправлен тест= ', response.data)

      this.setState({
        quiz: [],
        rightAnswerId: 1,
        isFormValid: false,
        testName: createTestName(),
        formControls: createFormControls(),
        newTestAdded: !this.state.newTestAdded,
        responseDataName: Object.values(response.data)[0],

        //FileLoaderState
        file: null,
        preview: '',
        url: '',
        progress: 0,
        showLoader: false,
      })
    } catch (error) {
      // console.log(error)
    }
    // window.setTimeout(() => console.log('QuizCreator - this.state', this.state), 1000)

  }

  // ===== Обработчик Изменения полей Input =====
  changeHandler = (value, controlName, controlsToRender) => {
    const formControls = {...this.state[controlsToRender]}
    const control = {...formControls[controlName]}

    control.value = value
    /*
          //Убрать надпись "Введите название теста", не сделано до конца:
          value === ''
          ? value = control.value
          : value = ''
    */
    control.touched = true
    control.valid = validate(control.value, control.validation)

    formControls[controlName] = control

    this.setState({
      [controlsToRender]: formControls,
      isFormValid: validateForm(formControls)
    })
  }

  // ===== Рендеринг полей Input в зависимости от controlsToRender =====
  renderControls(controlsToRender) {
    return Object.keys(this.state[controlsToRender]).map((controlName, index) => {
      const control = this.state[controlsToRender][controlName]
      return (
        <React.Fragment
          key={controlName + index}
        >
          <Input
            label={control.label}
            value={control.value}
            valid={control.valid}
            shouldValidate={!!control.validation}
            touched={control.touched}
            errorMessage={control.errorMessage}
            onChange={event => this.changeHandler(event.target.value, controlName, controlsToRender)}
          />
          {index === 0 && controlsToRender !== 'testName' ? <hr/> : null}
        </React.Fragment>
      )
    })
  }

  // ===== Обработчик Select =====
  selectChangeHandler = event => {
    this.setState({
      rightAnswerId: +event.target.value
    })
  }

  // ===== Обработчик Добавить еще один тест =====
  addOneMoreQuizHandler = () => {
    this.setState({
      quiz: [],
      rightAnswerId: 1,
      isFormValid: false,
      testName: createTestName(),
      formControls: createFormControls(),
      newTestAdded: false,
      responseDataName: '',

      //FileLoaderState
      file: null,
      preview: '',
      url: '',
      progress: 0,
      showLoader: false,
    })
  }

  // onTestButtonClick = () => {
  //   window.setTimeout(() => console.log('TEST this.state = ', this.state), 100)
  // }

  render() {

    // ===== Рендеринг FileLoader начало=====
    let renderFileLoader
    renderFileLoader =
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.FileLoader}>
            {this.state.url
              ? <p>Изображение успешно загружено:</p>
              : <p>Добавьте изображение к текущему вопросу</p>
            }

            {this.state.showLoader && this.state.progress !== 100
              ? <div className={classes.FileLoader_loader}>
                <Loader/>
              </div>
              :
              <div className={classes.FileLoader_div_for_img}>
                <img
                  src={
                    this.state.url
                      ? this.state.url
                      : this.state.preview
                      ? this.state.preview
                      : 'https://placehold.co/400x300'
                  } alt="image preview" width='100%' height='auto'/>
              </div>
            }

            <div className={classes.FileLoader_div_for_buttons}>
              <label className={classes.FileLoader_InputTypeFileButton}>
                <input
                  type="file"
                  onChange={this.handleImagePreview}
                />
                Выбрать файл
              </label>
              {this.state.isLoggedIn
                ?
                <button
                  className={classes.FileLoader_UploadFileButton}
                  type="submit"
                  onClick={this.fileUploadFirebase}
                  // onClick={this.fileUploadAxios}
                >
                  Загрузить
                </button>
                : null}
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.FileLoader_mobile}>
            {this.state.url
              ? <p>Изображение успешно загружено:</p>
              : <p>Добавьте изображение к текущему вопросу</p>
            }

            {this.state.showLoader && this.state.progress !== 100
              ? <div className={classes.FileLoader_mobile_loader}>
                <Loader/>
              </div>
              :
              <div className={classes.FileLoader_mobile_div_for_img}>
                <img
                  src={
                    this.state.url
                      ? this.state.url
                      : this.state.preview
                      ? this.state.preview
                      : 'https://placehold.co/300x200'
                  } alt="image preview" width='100%' height='auto'/>
              </div>
            }

            <div className={classes.FileLoader_mobile_div_for_buttons}>
              <label className={classes.FileLoader_mobile_InputTypeFileButton}>
                <input
                  type="file"
                  onChange={this.handleImagePreview}
                />
                Выбрать файл
              </label>
              {this.state.isLoggedIn
                ?
                <button
                  className={classes.FileLoader_mobile_UploadFileButton}
                  type="submit"
                  onClick={this.fileUploadFirebase}
                  // onClick={this.fileUploadAxios}
                >
                  Загрузить
                </button>
                : null}
            </div>
          </div>
        </MediaQuery>
      </>

    /*  renderControls() {
        return Object.keys(this.state.formControls).map((controlName, index) => {
          const control = this.state.formControls[controlName]
          return (
            <React.Fragment
              key={controlName + index}
            >
              <Input
                label={control.label}
                value={control.value}
                valid={control.valid}
                shouldValidate={!!control.validation}
                touched={control.touched}
                errorMessage={control.errorMessage}
                onChange={event => this.changeHandler(event.target.value, controlName)}
              />
              {index === 0 ? <hr/> : null}
            </React.Fragment>
          )
        })
      }*/
// ===== Рендеринг FileLoader конец=====

    const quizAuthIdToken = this.state.quizAuthIdToken

    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.Component_wrapper}>
            <div className={classes.Component_div_for_image_upper}>
              {this.state.newTestAdded
                ?
                <img className={classes.Component_image_upper_created} src='/img/quiz_created_background.svg'
                     alt='quiz_created_background' width='300' height='303'/>
                :
                <img className={classes.Component_image_upper_creator} src='/img/quiz_creator_background.svg'
                     alt='quiz_creator_background' width='334' height='297'/>}
            </div>
            {this.state.newTestAdded
              ?
              <div className={classes.QuizCreated}>
                <h1>Новый тест успешно создан</h1>
                <div className={classes.QuizCreated_wrapper}>
                  <ul>
                    <li>
                      <NavLink
                        className={classes.QuizCreator_NavLink}
                        to={`/quiz/${this.state.responseDataName}`}
                        activestyle={{color: "red"}}
                      >
                        Перейти в созданный тест
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.QuizCreator_NavLink}
                        to={'/quiz-list'}
                        activestyle={{color: "red"}}
                      >
                        Перейти к списку тестов
                      </NavLink>
                    </li>
                  </ul>

                  <ButtonGreen
                    onClick={this.addOneMoreQuizHandler}
                  >
                    Создать еще один тест
                  </ButtonGreen>
                </div>
              </div>
              :
              <div className={classes.QuizCreator}>
                <h1>Форма создания теста</h1>
                <form className={classes.QuizCreator_form}
                      onSubmit={this.submitHandler}>
                  {this.renderControls('testName')}
                  {/*<FileLoader/>*/}
                  {renderFileLoader}
                  {this.renderControls('formControls')}
                  <Select
                    label="Выберите правильный ответ"
                    value={this.state.rightAnswerId}
                    onChange={this.selectChangeHandler}
                    options={[
                      {text: 1, value: 1},
                      {text: 2, value: 2},
                      {text: 3, value: 3},
                      {text: 4, value: 4}
                    ]}
                  />
                  {this.state.isLoggedIn
                    ?
                    <div className={classes.QuizCreator_form_div_for_bottom_buttons}>
                      <ButtonGreen
                        onClick={this.addQuestionHandler}
                        disabled={!this.state.isFormValid}
                      >
                        Добавить вопрос
                      </ButtonGreen>
                      <ButtonBlue
                        onClick={this.createQuizHandler}
                        disabled={this.state.quiz.length === 0}
                      >
                        Создать тест
                      </ButtonBlue>
                      {/*<button
              onClick={this.onTestButtonClick}
            >
              тест
            </button>*/}
                    </div>
                    :
                    <p>Для создания теста необходимо&nbsp;
                      <NavLink
                        to={'/auth'}
                      >
                        Авторизоваться
                      </NavLink>
                    </p>
                  }
                </form>
              </div>}
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Component_wrapper_mobile}>
            <div className={classes.Component_div_for_image_upper_mobile}>
              {this.state.newTestAdded
                ?
                <img className={classes.Component_image_upper_created_mobile} src='/img/quiz_created_background.svg'
                     alt='quiz_created_background' width='300' height='303'/>
                :
                <img className={classes.Component_image_upper_creator_mobile} src='/img/quiz_creator_background.svg'
                     alt='quiz_creator_background' width='334' height='297'/>}
            </div>
            {this.state.newTestAdded
              ?
              <div className={classes.QuizCreated_mobile}>
                <h1>Новый тест успешно создан</h1>
                <div className={classes.QuizCreated_wrapper_mobile}>
                  <ul>
                    <li>
                      <NavLink
                        className={classes.QuizCreator_NavLink}
                        to={`/quiz/${this.state.responseDataName}`}
                        activestyle={{color: "red"}}
                      >
                        Перейти в созданный тест
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.QuizCreator_NavLink}
                        to={'/quiz-list'}
                        activestyle={{color: "red"}}
                      >
                        Перейти к списку тестов
                      </NavLink>
                    </li>
                  </ul>

                  <ButtonGreen
                    onClick={this.addOneMoreQuizHandler}
                  >
                    Создать еще один тест
                  </ButtonGreen>
                </div>
              </div>
              :
              <div className={classes.QuizCreator_mobile}>
                <h1 className={classes.Container_mobile_h1_for_QuizCreator}>Форма создания теста</h1>
                <form className={classes.QuizCreator_form_mobile}
                      onSubmit={this.submitHandler}>
                  {this.renderControls('testName')}
                  {/*<FileLoader/>*/}
                  {renderFileLoader}
                  {this.renderControls('formControls')}
                  <Select
                    label="Выберите правильный ответ"
                    value={this.state.rightAnswerId}
                    onChange={this.selectChangeHandler}
                    options={[
                      {text: 1, value: 1},
                      {text: 2, value: 2},
                      {text: 3, value: 3},
                      {text: 4, value: 4}
                    ]}
                  />
                  {this.state.isLoggedIn
                    ?
                    <div className={classes.QuizCreator_form_mobile_div_for_bottom_buttons}>
                      <ButtonGreen
                        onClick={this.addQuestionHandler}
                        disabled={!this.state.isFormValid}
                      >
                        Добавить вопрос
                      </ButtonGreen>
                      <ButtonBlue
                        onClick={this.createQuizHandler}
                        disabled={this.state.quiz.length === 0}
                      >
                        Создать тест
                      </ButtonBlue>
                      {/*<button
              onClick={this.onTestButtonClick}
            >
              тест
            </button>*/}
                    </div>
                    :
                    <p>Для создания теста необходимо&nbsp;
                      <NavLink
                        to={'/auth'}
                      >
                        Авторизоваться
                      </NavLink>
                    </p>
                  }
                </form>
              </div>}
          </div>
        </MediaQuery>
      </>
    )
  }
}