import React, {Component} from 'react'
import classes from './AnswerItem.module.css'

class AnswerItem extends Component {
  state = {
    hoverImitationStarted: false,
  }
  render() {
    const cls = [classes.AnswerItem]

    if (this.props.answerState) {
      cls.push(classes[this.props.answerState[this.props.answer.id]])
    }

    if (this.state.hoverImitationStarted) {
      cls.push(classes.AnswerItem_hover_imitation)
    }

    const startHoverImitation = () => {
      this.setState({
        hoverImitationStarted: true
      })
    }

    const stopHoverImitation = () => {
      this.setState({
        hoverImitationStarted: false
      })
    }

    return (
      <li
        className={cls.join(' ')}
        /*onMouseEnter={() => cls.push(classes.AnswerItem_hover_imitation)}*/
        onMouseEnter={startHoverImitation}
        onMouseLeave={stopHoverImitation}
        onClick={() => {
          this.props.onAnswerClick(this.props.answer.id)
          stopHoverImitation()
        }}
      >
        {this.props.index + 1}. {this.props.answer.text}
      </li>
    )
  }
}

export default AnswerItem