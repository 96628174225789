import React from "react";
import classes from "./PersonalDataPolicy.module.css";
import MediaQuery from "react-responsive";
// import MediaQuery from "react-responsive";

const PersonalDataPolicy = (props) => {
  let renderPersonalData
  renderPersonalData =
    <p>
      Отказ от ответственности<br/>

      Администрация Сайта отказывается от каких-либо заверений и гарантий, предоставление которых может иным образом
      подразумеваться, и отказывается от ответственности в отношении Сайта, Содержимого и их использования.<br/>

      Ни при каких обстоятельствах Администрация Сайта не будет нести ответственности ни перед какой стороной за
      какой-либо прямой, непрямой, особый или иной косвенный ущерб в результате любого использования информации на
      этом Сайте или на любом другом сайте, на который имеется гиперссылка с данного cайта, за любую упущенную выгоду,
      приостановку хозяйственной деятельности, потерю программ или данных в Ваших информационных системах или иным
      образом, возникшие в связи с доступом, использованием или невозможностью использования Сайта, Содержимого или
      какого-либо связанного интернет-сайта, или неработоспособностью, ошибкой, упущением, перебоем, дефектом,
      простоем в работе или задержкой в передаче, компьютерным вирусом или системным сбоем, даже если администрация
      будет явно поставлена в известность о возможности такого ущерба.<br/>

      Администрация сайта не несет ответственности за содержимое сайта, создаваемое пользователями с помощью функционала
      данного сайта и имеет право удалить такое содержимое без оповещения и согласия пользователей сайта. <br/>

      Пользователь соглашается с тем, что все возможные споры будут разрешаться по нормам Российского права.<br/>

      Пользователь соглашается с тем, что нормы и законы о защите прав потребителей не могут быть применимы к
      использованию им Сайта, поскольку Сайт не оказывает никаких услуг и не принимает оплату. Используя данный Сайт,
      Вы выражаете свое согласие с "Отказом от ответственности" и установленными Правилами и принимаете всю
      ответственность, которая может быть на Вас возложена.<br/>

      Сайт предназначен исключительно для использования в ознакомительных целях.<br/><br/>
    </p>

  return (
    <>
      <MediaQuery minWidth={600}>
        {props.authState.personalDataPolicyShow
          ?
          <div className={classes.personalData_wrapper}>
            <a
              className={classes.personalData_close}
              onClick={props.onClick}
            >
              X
            </a>
            <div className={classes.personalData}>
              {renderPersonalData}
            </div>
          </div>
          : null}
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        {props.authState.personalDataPolicyShow
          ?
          <div className={classes.personalData_wrapper_mobile}>
            <a
              className={classes.personalData_close_mobile}
              onClick={props.onClick}
            >
              X
            </a>
            <div className={classes.personalData_mobile}>
              {renderPersonalData}
            </div>
          </div>
          : null}
      </MediaQuery>
    </>
  )
}

export default PersonalDataPolicy