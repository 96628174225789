import React, {Component} from 'react'
import classes from '../CommonContainer.module.css'
import ActiveQuiz from '../../Components/ActiveQuiz/ActiveQuiz'
import FinishedQuiz from '../../Components/FinishedQuiz/FinishedQuiz'
import axios from "../../axios/axios-quiz"
import Loader from "../../Components/UI/Loader/Loader";
import MediaQuery from "react-responsive";

export default class Quiz extends Component {
  state = {
    results: {}, // {[id]: 'success' ' error'}
    answerState: null, // {[answerId]: 'success' 'error'}
    currentQuestion: 0,
    isQuizFinished: false,
    correctAnswersCount: 0,
    userAnswers: [],
    quizQuestions: [],
    userQuestionsAnswers: [],
    loading: true,
    quiz: [],
  }

  onAnswerClickHandler = (answerId) => {

    const question = this.state.quiz[this.state.currentQuestion]
    const results = this.state.results

    if (answerId === this.state.quiz[this.state.currentQuestion].rightAnswerId) {
      results[question.id] = 'success'
      this.setState({
        answerState: {[answerId]: 'success'},
        correctAnswersCount: this.state.correctAnswersCount + 1,
        results: results
      })
    } else {
      results[question.id] = 'error'
      this.setState({
        answerState: {[answerId]: 'error'},
        results: results
      })

    }


    window.setTimeout(() => {
      this.state.currentQuestion + 1 === this.state.quiz.length
        ? this.setState({
          isQuizFinished: true
        })
        : this.setState({
          currentQuestion: this.state.currentQuestion + 1,
          answerState: {[answerId]: null},
        })
    }, 1000)

    this.state.userAnswers.push(this.state.quiz[this.state.currentQuestion].answers[answerId - 1].text)

    this.state.quizQuestions.push(this.state.quiz[this.state.currentQuestion].question)

    const userQuestionsAnswers = this.state.userQuestionsAnswers
    userQuestionsAnswers.push([])
    userQuestionsAnswers[this.state.currentQuestion].push(
      this.state.quiz[this.state.currentQuestion].question,
      this.state.quiz[this.state.currentQuestion].answers[answerId - 1].text,
      answerId
    )
    this.setState({
      userQuestionsAnswers: userQuestionsAnswers
    })

    // console.log("userQuestionsAnswers = ", this.state.quiz[this.state.currentQuestion].question)
    // console.log("userQuestionsAnswers = ", this.state.quiz[this.state.currentQuestion].answers[answerId - 1].text)
    // console.log("userQuestionsAnswers = ", this.state.userQuestionsAnswers)
    // console.log("currentQuestion = ", this.state.currentQuestion)
    // console.log("this.state.quiz.length = ", this.state.quiz.length)
    // console.log("isQuizFinished = ", this.state.isQuizFinished)
  }

  onRepeatHandler = () => {
    this.setState({
      results: {}, // {[id]: 'success' ' error'}
      answerState: null, // {[answerId]: 'success' 'error'}
      currentQuestion: 0,
      isQuizFinished: false,
      correctAnswersCount: 0,
      userAnswers: [],
      quizQuestions: [],
      userQuestionsAnswers: []
    })
  }


  async componentDidMount() {
    let quizAuthUID
    let quizAuthIdToken
    if (this.props.quizUrl) {
      quizAuthUID = this.props.quizUrl
    } else if (localStorage.getItem('quizAuthUID')) {
      quizAuthUID = localStorage.getItem('quizAuthUID')
      quizAuthIdToken = localStorage.getItem('quizAuthIdToken')
    }

    // quizAuthUID = 'ogYZTt5LF6YObyO5u3f6WiNmi8D3'
    //console.log('this.props.match.params.id = ', this.props.match.params.id)
    try {
      const response = await axios.get(`/quizes/${quizAuthUID}/${document.location.pathname.split('/')[2]}.json?auth=${quizAuthIdToken}`)
      const quiz = response.data
      // console.log('quiz = ', quiz)
      //const responseData = Object.keys(response.data)
      //console.log('Вопрос 1= ', response.data[responseData[0]][0].question)
      // console.log('Вопрос 2= ', response.data[responseData[1]][1].question)
      // console.log('response.data = ', response.data)
      // console.log('location.pathname = ', document.location.pathname)
      // console.log('location.pathname = ', document.location.pathname.split('/')[1])
      // console.log('location.pathname = ', document.location.pathname.split('/')[2])
      //const responseUrl = Object.keys(response.data).map((url, index) => {
      // return console.log('responseUrl = ', responseUrl)
      //console.log(`/quizes/${Object.keys(response.data)[0]}`)
      //})
      //return console.log('response.data = ', response.data)
      this.setState({
        quiz,
        loading: false
      })
    } catch (error) {
      // console.log(error)
    }
  }

  renderContainer() {
    return this.state.isQuizFinished
      ? <FinishedQuiz
        correctAnswersCount={this.state.correctAnswersCount}
        quiz={this.state.quiz}
        userAnswers={this.state.userAnswers}
        results={this.state.results}
        quizQuestions={this.state.quizQuestions}
        userQuestionsAnswers={this.state.userQuestionsAnswers}
        onRepeat={this.onRepeatHandler}
      />
      :
      this.state.loading
        ? <Loader/>
        : <React.Fragment>
          <h1 className={classes.Container_mobile_h1_for_Quiz }>Ответьте на все вопросы</h1>
          <ActiveQuiz
            currentQuestion={this.state.currentQuestion}
            quiz={this.state.quiz}
            question={this.state.quiz[this.state.currentQuestion].question}
            url={this.state.quiz[this.state.currentQuestion].url}
            answers={this.state.quiz[this.state.currentQuestion].answers}
            onAnswerClick={this.onAnswerClickHandler}
            answerState={this.state.answerState}
          />
        </React.Fragment>
  }

  render() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.Component_wrapper}>
            <div className={classes.Component_div_for_image_upper}>
              {this.state.isQuizFinished
                ?
                <img className={classes.Component_image_upper} src='/img/results_background.svg'
                     alt='active_quiz_background' width='241' height='296'/>
                :
                <img className={classes.Component_image_upper} src='/img/active_quiz_background.svg'
                     alt='active_quiz_background' width='241' height='296'/>}
            </div>
            <div className={classes.Container}>
              {this.renderContainer()}
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Component_wrapper_mobile}>
            <div className={classes.Component_div_for_image_upper_mobile}>
              {this.state.isQuizFinished
                ?
                <img className={classes.Component_image_upper_mobile} src='/img/results_background.svg'
                     alt='active_quiz_background' width='241' height='296'/>
                :
                <img className={classes.Component_image_upper_mobile} src='/img/active_quiz_background.svg'
                     alt='active_quiz_background' width='241' height='296'/>}
            </div>
            <div className={classes.Container_mobile}>
              {this.renderContainer()}
            </div>
          </div>
        </MediaQuery>
      </>
    )
  }
}

