import React from 'react'
import classes from './FinishedQuiz.module.css'
import ButtonGreen from '../UI/Button/ButtonGreen/ButtonGreen'
import {NavLink} from "react-router-dom";

const FinishedQuiz = props => {

  const content = (userQuestionsAnswers) => {
    return (
      userQuestionsAnswers.map((questionAnswer, index) => {
        return (
          <div key={questionAnswer + index}>
            <div className={classes.FinishedQuiz_questions}>
              <p>{questionAnswer[0]}</p>
            </div>
            <div className={classes.FinishedQuiz_answers}>
              {Object.values(props.results)[index] === 'success'
                ? <i className="fa-solid fa-check"></i>
                : <strong>Х</strong>
              } {questionAnswer[2]}. {questionAnswer[1]}
            </div>
          </div>
        )
      })
    )
  }

  return (
    <div className={classes.FinishedQuiz}>
      <h1>Ваши результаты:</h1>
      <div className={classes.FinishedQuiz_wrapper}>
        <p className={classes.FinishedQuiz_CorrectAnswersCountText}>
          Правильно {props.correctAnswersCount} из {props.quiz.length}:
        </p>
        <div className={classes.FinishedQuiz_content}>
          {content(props.userQuestionsAnswers)}
        </div>
        <ButtonGreen
          onClick={props.onRepeat}
        >
          Пройти заново
        </ButtonGreen>
        <NavLink
          className={classes.FinishedQuiz_NavLink}
          to={'/quiz-list'}
          activestyle={{color: "red"}}
        >
          Перейти к списку тестов
        </NavLink>
        {/*<ButtonGreen
          onClick={console.log(props)}
        >
          test
        </ButtonGreen>*/}
      </div>
    </div>
  )
}

export default FinishedQuiz