import React, {Component} from 'react'
import classes from './Auth.module.css'
import ButtonGreen from "../../Components/UI/Button/ButtonGreen/ButtonGreen";
import ButtonBlue from "../../Components/UI/Button/ButtonBlue/ButtonBlue";
import Input from "../../Components/UI/Input/Input";
import PersonalDataPolicy from "../PersonalDataPolicy/PersonalDataPolicy";
import axios from "axios";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import firebase from "../../firebase/firebase"
import {NavLink} from "react-router-dom";
import MediaQuery from "react-responsive";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export default class Auth extends Component {
  state = {
    isFormValid: false,
    personalDataPolicyShow: false,
    isLoggedIn: false,
    isRegistered: false,
    axiosErrorEmailExists: false,
    axiosErrorInvalidPasssword: false,
    axiosErrorTooManyAttempts: false,
    axiosErrorEmailNotFound: false,
    formControls: {
      login: {
        value: '',
        name: "login",
        type: "text",
        placeholder: "Введите e-mail",
        label: "Логин",
        errorMessage: "Введите корректный email-адрес",
        shouldValidate: true,
        touched: false,
        valid: false,
        validation: {
          required: true,
          email: true,
        }
      },
      password: {
        value: '',
        name: "password",
        type: "text",
        placeholder: "Введите пароль",
        label: "Пароль",
        errorMessage: "Пароль должен быть длиннее 5 символов",
        shouldValidate: true,
        touched: false,
        valid: false,
        validation: {
          required: true,
          minLength: 6
        }
      }
    }

  }

  componentDidMount() {
    let quizAuthExpiresIn = window.localStorage.getItem('quizAuthExpiresIn')
    quizAuthExpiresIn > Date.now()
      ? this.setState({
        isLoggedIn: true
      })
      : this.setState({
        isLoggedIn: false
      })
  }

  submitHandler = event => {
    event.preventDefault()
  }

  personalDataPolicyHandler = () => {
    this.setState({
      personalDataPolicyShow: !this.state.personalDataPolicyShow
    })
  }

  validateControl(value, validation) {
    if (!validation) {
      return true
    }

    let isValid = true

    if (validation.required) {
      isValid = value.trim !== '' && isValid
    }

    if (validation.email) {
      isValid = validateEmail(value) && isValid
    }

    if (validation.minLength) {
      isValid = value.length >= validation.minLength && isValid
    }

    return isValid
  }

  onChangeHandler = (event, controlName) => {
    // console.log(`${controlName}:`, event.target.value)

    const formControls = {...this.state.formControls}
    const control = {...formControls[controlName]}

    control.value = event.target.value
    control.touched = true
    control.valid = this.validateControl(control.value, control.validation)

    formControls[controlName] = control

    let isFormValid = true

    Object.keys(formControls).forEach(name => {
      isFormValid = formControls[name].valid && isFormValid
    })

    this.setState({
      formControls,
      isFormValid,
      axiosErrorEmailExists: false,
      axiosErrorInvalidPasssword: false,
    })
  }

  // firebase rest auth:  https://firebase.google.com/docs/reference/rest/auth?hl=sr#section-create-email-password

  loginHandler = async () => {
    const authData = {
      email: this.state.formControls.login.value,
      password: this.state.formControls.password.value,
      returnSecureToken: true
    }
    try {
      const response = await axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyABpYZzSStA9A8iHaT8JBkPN33nRtMDgdA', authData)
      // console.log(response.data)
      this.setState({
        isLoggedIn: true,
      })
      window.localStorage.setItem('quizAuthUID', response.data.localId)
      window.localStorage.setItem('quizAuthIdToken', response.data.idToken)
      window.localStorage.setItem('quizAuthExpiresIn', Date.now() + Number(response.data.expiresIn) * 1000)
      // window.setTimeout(() => console.log('quizAuthUID = ', localStorage.getItem('quizAuthUID')), 150)
      // window.setTimeout(() => console.log('quizAuthExpiresIn = ', localStorage.getItem('quizAuthExpiresIn')), 150)
    } catch (e) {
      // console.log(e)
      // console.log(e.response.data.error.message)
      // 'INVALID_PASSWORD'
      if (e.response.data.error.message === 'INVALID_PASSWORD') {
        this.setState({
          axiosErrorInvalidPasssword: true,
        })
      }
      if (e.response.data.error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.') {
        this.setState({
          axiosErrorTooManyAttempts: true,
        })
      }
      if (e.response.data.error.message === 'EMAIL_NOT_FOUND') {
        this.setState({
          axiosErrorEmailNotFound: true,
        })
      }
    }
  }

  registerHandler = async () => {
    const authData = {
      email: this.state.formControls.login.value,
      password: this.state.formControls.password.value,
      returnSecureToken: true
    }
    try {
      const response = await axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyABpYZzSStA9A8iHaT8JBkPN33nRtMDgdA', authData)
      // console.log(response.data)
      this.setState({
        isRegistered: true,
      })
      window.localStorage.setItem('quizAuthUID', response.data.localId)
      window.localStorage.setItem('quizAuthIdToken', response.data.idToken)
      window.localStorage.setItem('quizAuthExpiresIn', Date.now() + Number(response.data.expiresIn) * 1000)
      // window.setTimeout(() => console.log('quizAuthUID = ', localStorage.getItem('quizAuthUID')), 150)
    } catch (e) {
      // console.log(e)
      // console.log(e.response.data.error.message)
      //EMAIL_EXISTS
      if (e.response.data.error.message === 'EMAIL_EXISTS') {
        this.setState({
          axiosErrorEmailExists: true,
        })
      }
    }
  }


  renderInputs() {
    return Object.keys(this.state.formControls).map((controlName, index) => {
      const control = this.state.formControls[controlName]
      return (
        <Input
          key={control + index}
          name={control.name}
          type={control.type}
          placeholder={control.placeholder}
          label={control.label}
          value={control.value}
          errorMessage={control.errorMessage}
          shouldValidate={control.shouldValidate}
          touched={control.touched}
          valid={control.valid}
          onChange={event => this.onChangeHandler(event, controlName)}
        />
      )
    })
  }


  renderPrivacyBlock() {
    return (
      <p className={classes.Auth_personalData}>
        Сайт создан в ознакомительных целях и не является коммерческим.
        <br/>
        Используя данный Сайт, Вы выражаете свое согласие с &ensp;
        <a
          className={classes.Auth_personalData_link}
          onClick={this.personalDataPolicyHandler}
        >
          "Отказом от ответственности"
        </a>
      </p>
    )
  }

  renderAuthBlock() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <form
            className={classes.Auth_form}
            name="auth"
            onSubmit={this.submitHandler}
          >
            {this.renderInputs()}
            {this.renderPrivacyBlock()}

            <div className={classes.Auth_div_for_buttons}>
              <ButtonGreen
                disabled={!this.state.isFormValid}
                onClick={this.loginHandler}
              >
                Вход
              </ButtonGreen>
              <ButtonBlue
                disabled={!this.state.isFormValid}
                onClick={this.registerHandler}
              >
                Регистрация
              </ButtonBlue>
            </div>
            {this.state.axiosErrorEmailExists
              ? <p className={classes.Auth_login_password_error}> Указанный Вами e-mail уже зарегистрирован</p>
              : null}
            {this.state.axiosErrorInvalidPasssword
              ? <p className={classes.Auth_login_password_error}> Введен неверный логин или пароль</p>
              : null}
            {this.state.axiosErrorTooManyAttempts
              ? <p className={classes.Auth_login_password_error}> Замечена подозрительная активность, аккаунт временно
                заблокирован. Попробуйте войти еще раз позже или смените пароль</p>
              : null}
            {this.state.axiosErrorEmailNotFound
              ? <p className={classes.Auth_login_password_error}> Указанный Вами e-mail не найден</p>
              : null}
          </form>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <form
            className={classes.Auth_form_mobile}
            name="auth"
            onSubmit={this.submitHandler}
          >
            {this.renderInputs()}
            {this.renderPrivacyBlock()}

            <div className={classes.Auth_div_for_buttons_mobile}>
              <ButtonGreen
                disabled={!this.state.isFormValid}
                onClick={this.loginHandler}
              >
                Вход
              </ButtonGreen>
              <ButtonBlue
                disabled={!this.state.isFormValid}
                onClick={this.registerHandler}
              >
                Регистрация
              </ButtonBlue>
            </div>
            {this.state.axiosErrorEmailExists
              ? <p className={classes.Auth_login_password_error_mobile}> Указанный Вами e-mail уже зарегистрирован</p>
              : null}
            {this.state.axiosErrorInvalidPasssword
              ? <p className={classes.Auth_login_password_error_mobile}> Введен неверный логин или пароль</p>
              : null}
            {this.state.axiosErrorTooManyAttempts
              ? <p className={classes.Auth_login_password_error_mobile}> Замечена подозрительная активность, аккаунт
                временно
                заблокирован. Попробуйте войти еще раз позже или смените пароль</p>
              : null}
            {this.state.axiosErrorEmailNotFound
              ? <p className={classes.Auth_login_password_error}> Указанный Вами e-mail не найден</p>
              : null}
          </form>
        </MediaQuery>
      </>
    )
  }

  renderLoggedInBlock() {
    return (
      <div className={classes.Auth_personalData_wrapper}>
        <p className={classes.Auth_personalData_login_register}>
          Вы успешно авторизовались.
        </p>
        <ButtonBlue
          // disabled={!this.state.isFormValid}
          onClick={this.onExitButtonClick}
        >
          Выйти
        </ButtonBlue>
      </div>
    )
  }

  renderRegisterBlock() {
    return (
      <div className={classes.Auth_personalData_wrapper}>
        <p className={classes.Auth_personalData_login_register}>
          Вы успешно зарегистрировались и авторизовались.
        </p>
        <ButtonBlue
          // disabled={!this.state.isFormValid}
          onClick={this.onExitButtonClick}
        >
          Выйти
        </ButtonBlue>
      </div>
    )
  }

  onTestButtonClick = () => {
    // window.setTimeout(() => console.log('TEST this.state = ', this.state), 300)
    // window.setTimeout(() => console.log('TEST localStorage = ', localStorage), 300)
    // window.setTimeout(() => console.log('TEST localStorage-quizAuthUID = ', localStorage.getItem('quizAuthUID')), 300)
    // window.setTimeout(() => console.log('TEST localStorage-quizAuthIdToken = ', localStorage.getItem('quizAuthIdToken')), 300)
    // console.log('TEST Date.now() = ', Date.now())
    // window.localStorage.removeItem('quizAuthUID')
  }

  onLocalStorageClearButtonClick = () => {
    window.localStorage.clear()
  }

  onExitButtonClick = () => {
    // window.setTimeout(() => console.log('TEST this.state = ', this.state), 300)
    // window.setTimeout(() => console.log('TEST localStorage = ', localStorage), 300)
    window.localStorage.removeItem('quizAuthUID')
    window.localStorage.removeItem('quizAuthIdToken')
    window.localStorage.removeItem('quizAuthExpiresIn')
    this.setState({
      isFormValid: false,
      personalDataPolicyShow: false,
      isLoggedIn: false,
      isRegistered: false,
      formControls: {
        login: {
          value: '',
          name: "login",
          type: "text",
          placeholder: "Введите e-mail",
          label: "Логин",
          errorMessage: "Введите корректный email-адрес",
          shouldValidate: true,
          touched: false,
          valid: false,
          validation: {
            required: true,
            email: true
          }
        },
        password: {
          value: '',
          name: "password",
          type: "text",
          placeholder: "Введите пароль",
          label: "Пароль",
          errorMessage: "Введите корректный пароль",
          shouldValidate: true,
          touched: false,
          valid: false,
          validation: {
            required: true,
            minLength: 6
          }
        }
      }
    })

  }

  render() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.Component_wrapper}>
            <div className={classes.Component_div_for_image_upper}>
              <img className={classes.Component_image_upper} src='/img/auth_background.svg'
                   alt='auth_background' width='250' height='141'/>
            </div>
            <div className={classes.Auth}>
              <PersonalDataPolicy
                authState={this.state}
                onClick={this.personalDataPolicyHandler}
              />
              <h1>Авторизация</h1>

              {/*          {window.localStorage.getItem('quizAuthUID') !== (null && 'ogYZTt5LF6YObyO5u3f6WiNmi8D3')
            ? this.renderRegisterBlock()
            :*/}

              {this.state.isLoggedIn || this.state.isRegistered
                ?
                (this.state.isLoggedIn && !this.state.isRegistered) || (this.state.isLoggedIn && this.state.isRegistered)
                  ?
                  this.renderLoggedInBlock()
                  :
                  !this.state.isLoggedIn && this.state.isRegistered
                    ?
                    this.renderRegisterBlock()
                    :
                    this.renderAuthBlock()
                :
                this.renderAuthBlock()
              }
              {/*          <button
            onClick={this.onTestButtonClick}
          >
            тест
          </button>*/}
              {/*          <button
            onClick={this.onLocalStorageClearButtonClick}
          >
            LocalStorageClear
          </button>*/}
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Component_wrapper_mobile}>
            <div className={classes.Component_div_for_image_upper_mobile}>
              <img className={classes.Component_image_upper_mobile} src='/img/auth_background.svg'
                   alt='auth_background' width='880' height='497'/>
            </div>
            <div className={classes.Auth_mobile}>
              <PersonalDataPolicy
                authState={this.state}
                onClick={this.personalDataPolicyHandler}
              />
              <h1>Авторизация</h1>

              {/*          {window.localStorage.getItem('quizAuthUID') !== (null && 'ogYZTt5LF6YObyO5u3f6WiNmi8D3')
            ? this.renderRegisterBlock()
            :*/}

              {this.state.isLoggedIn || this.state.isRegistered
                ?
                (this.state.isLoggedIn && !this.state.isRegistered) || (this.state.isLoggedIn && this.state.isRegistered)
                  ?
                  this.renderLoggedInBlock()
                  :
                  !this.state.isLoggedIn && this.state.isRegistered
                    ?
                    this.renderRegisterBlock()
                    :
                    this.renderAuthBlock()
                :
                this.renderAuthBlock()
              }
              {/*          <button
            onClick={this.onTestButtonClick}
          >
            тест
          </button>*/}
              {/*          <button
            onClick={this.onLocalStorageClearButtonClick}
          >
            LocalStorageClear
          </button>*/}
            </div>
          </div>
        </MediaQuery>
      </>
    )
  }
}