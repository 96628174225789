import React from 'react'
import Layout from './hoc/Layout/Layout'
import {Route, Routes} from "react-router-dom";
import Quiz from "./Containers/Quiz/Quiz";

function App() {
  return (
    <Layout>
      <Quiz />
    </Layout>
  )
}

export default App
