import React, {Component} from 'react'
import classes from './Layout.module.css'
import Quiz from '../../Containers/Quiz/Quiz'
import Menu from '../../Components/UI/Menu/Menu'
import ButtonMenu from "../../Components/UI/Button/ButtonMenu/ButtonMenu";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import {Route, Routes, Navigate} from "react-router-dom";
import QuizList from "../../Containers/QuizList/QuizList";
import Auth from "../../Containers/Auth/Auth";
import QuizCreator from "../../Containers/QuizCreator/QuizCreator";
import MainPage from "../../Containers/MainPage/MainPage";
import MediaQuery from "react-responsive";

class Layout extends Component {
  state = {
    menuState: 'close'
  }

  onButtonMenuHandler = () => {
    this.setState({
      menuState: 'open'
    })
    // console.log('this.state.menuState= ', this.state.menuState)
  }

  onButtonMenuCloseHandler = () => {
    this.setState({
      menuState: 'close'
    })
    // console.log('this.state.menuState= ', this.state.menuState)
  }

  /*  renderBackgroundBottomImage() {
      return <div className={classes.Layout_div_for_image}>
        <img
          className={classes.Layout_image_man_monitor}
          src='/img/man_monitor_background.png'
          alt='man_monitor_background'
          width='500'
          height='288'/>
      </div>
    }*/

  renderBackgroundBottomImage() {
    return <>
      <MediaQuery minWidth={600}>
        <div className={classes.Layout_div_for_image}>
          {/*      <img
        className={classes.Layout_image_man_monitor}
        src='/img/man_monitor_background.png'
        alt='man_monitor_background'
        width='500'
        height='288'/>*/}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.Layout_div_for_image_mobile}>
          {/*      <img
        className={classes.Layout_image_man_monitor}
        src='/img/man_monitor_background.png'
        alt='man_monitor_background'
        width='500'
        height='288'/>*/}
        </div>
      </MediaQuery>
    </>
  }


  renderRoutes() {
    return <Routes>
      <Route path="/quiz-list" element={
        <>
          <QuizList/>
          {this.renderBackgroundBottomImage()}
        </>
      }
      />
      <Route path="/auth" element={
        <>
          <Auth/>
          {this.renderBackgroundBottomImage()}
        </>
      }
      />
      <Route path="/quiz-create" element={
        <>
          <QuizCreator/>
          {this.renderBackgroundBottomImage()}
        </>
      }
      />
      <Route path="/quiz/:id" element={
        <>
          <Quiz
            menuState={this.state.menuState}
            onButtonMenuCloseHandler={this.onButtonMenuCloseHandler}
          />
          {this.renderBackgroundBottomImage()}
        </>
      }
      />
      <Route path="/quiz/-NHIglEiPyQny2WNZ8cV" element={
        <>
          <Quiz
            menuState={this.state.menuState}
            onButtonMenuCloseHandler={this.onButtonMenuCloseHandler}
            quizUrl={'ogYZTt5LF6YObyO5u3f6WiNmi8D3'}
          />
          {this.renderBackgroundBottomImage()}
        </>
      }
      />
      <Route path="/" exact element={<MainPage/>}/>
      <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>
  }

  renderMenuButton() {
    return <ButtonMenu
      onButtonMenuHandler={this.onButtonMenuHandler}
      menuState={this.state.menuState}
    />
  }

  renderMenu() {
    return <Menu
      menuState={this.state.menuState}
      onButtonMenuCloseHandler={this.onButtonMenuCloseHandler}
    />
  }

  renderMenuBackdrop() {
    return this.state.menuState === 'open'
      ? <Backdrop
        onButtonMenuCloseHandler={this.onButtonMenuCloseHandler}
      />
      : null
  }

  // сделать для мобил свой лейаут .Layout_mobile с бэкграундом серым

  render() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.Layout}>
            {this.renderMenuButton()}
            {this.renderMenu()}
            <div className={classes.Layout_div_for_column}>
              {/*<div className={classes.Layout_wrapper}>*/}
              {/*            <div className={classes.Layout_div_for_image_upper}>
              <img className={classes.Layout_image_upper} src='/img/todo_list.png'
                   alt='man_monitor_background' width='241' height='296'/>
            </div>*/}
              {this.renderRoutes()}
              {/*</div>*/}
              {/*<div className={classes.Layout_div_for_image}>
            <img
              className={classes.Layout_image_man_monitor}
              src='/img/man_monitor_background.png'
              alt='man_monitor_background'
              width='500'
              height='288'/>
          </div>*/}
            </div>
            {this.renderMenuBackdrop()}
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.Layout}>
            {this.renderMenuButton()}
            {this.renderMenu()}
            <div className={classes.Layout_div_for_column}>
              {this.renderRoutes()}
            </div>
            {this.renderMenuBackdrop()}
          </div>
        </MediaQuery>
      </>
    )
  }
}

export default Layout
