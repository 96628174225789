import React from 'react'
import classes from './ButtonMenuClose.module.css'

const ButtonMenuClose = props => {
  return (
    <div className={classes.i}
         onClick={props.onButtonMenuCloseHandler}
    >
      <i className="fas fa-times-circle fa-2x"></i>
    </div>
  )
}

export default ButtonMenuClose