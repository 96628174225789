import React, {Component} from 'react'
import AnswerList from './AnswerList/AnswerList'
import classes from './ActiveQuiz.module.css'
import Loader from "../UI/Loader/Loader";
import MediaQuery from "react-responsive";

class ActiveQuiz extends Component {
  state = {
    imageIsLoaded: false,
  }

  /*  componentDidMount() {
      if (this.props.currentQuestion !== this.state.currentQuestionActiveQuiz) {
        this.setState({
          currentQuestionActiveQuiz: this.props.currentQuestion,
          imageIsLoaded: false,
        })
      }
    }*/

  render() {
    return (
      <>
        <MediaQuery minWidth={600}>
          <div className={classes.ActiveQuiz}>
            <p>{this.props.question}</p>
            <span>{`${this.props.currentQuestion + 1} из ${this.props.quiz.length}`}</span><br/>
            {this.props.url
              ? <div className={classes.ActiveQuiz_div_for_image}>
                {
                  this.state.imageIsLoaded
                    ? null
                    : <Loader/>
                }
                <img
                  src={this.props.url}
                  alt={'иллюстрация к вопросу'}
                  style={this.state.imageIsLoaded ? {display: 'block'} : {display: 'none'}}
                  onLoad={() => this.setState({imageIsLoaded: true})}
                />
              </div>
              : null}
            <AnswerList
              answers={this.props.answers}
              onAnswerClick={this.props.onAnswerClick}
              answerState={this.props.answerState}
            />
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <div className={classes.ActiveQuiz_mobile}>
            <p>{this.props.question}</p>
            <span>{`${this.props.currentQuestion + 1} из ${this.props.quiz.length}`}</span><br/>
            {this.props.url
              ? <div className={classes.ActiveQuiz_mobile_div_for_image}>
                {
                  this.state.imageIsLoaded
                    ? null
                    : <Loader/>
                }
                <img
                  src={this.props.url}
                  alt={'иллюстрация к вопросу'}
                  style={this.state.imageIsLoaded ? {display: 'block'} : {display: 'none'}}
                  onLoad={() => this.setState({imageIsLoaded: true})}
                />
              </div>
              : null}
            <AnswerList
              answers={this.props.answers}
              onAnswerClick={this.props.onAnswerClick}
              answerState={this.props.answerState}
            />
          </div>
        </MediaQuery>
      </>
    )
  }
}

export default ActiveQuiz