import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import classes from './Menu.module.css'
import ButtonMenuClose from '../Button/ButtonMenuClose/ButtonMenuClose'

const links = [
  {to: '/', label: 'Главная', activeStyle: '{color:"red"}'},
  {to: '/quiz-list', label: 'Список тестов', activeStyle: '{color:"red"}', exact: 'false'},
  {to: '/quiz/-NHIglEiPyQny2WNZ8cV', label: 'Пример. Тест по астрономии', activeStyle: '{color:"red"}', exact: 'true'},
  {to: 'quiz-create', label: 'Создать тест', activeStyle: '{color:"red"}', exact: 'false'},
  {to: 'auth', label: 'Авторизация', activeStyle: '{color:"red"}', exact: 'false'},
]

export default class Menu extends Component {
  renderLinks() {
    return links.map((link, index) => {
      return (
        <li
          key={link + index}
        >
          <NavLink
            className={classes.Menu_navLink}
            to={link.to}
            activestyle={{color: "red"}}
            onClick={this.props.onButtonMenuCloseHandler}
          >
            {link.label}
          </NavLink>
        </li>
      )
    })
  }

  state = {
    isLoggedIn: '',
  }

  componentDidMount() {
    window.localStorage.getItem('quizAuthUID')
    let quizAuthExpiresIn = window.localStorage.getItem('quizAuthExpiresIn')
    quizAuthExpiresIn > Date.now()
      ? this.setState({
        isLoggedIn: true
      })
      : this.setState({
        isLoggedIn: false
      })
  }

  render() {
    const cls = [classes.Menu]
    if (this.props.menuState === 'close') {
      cls.push(classes.close)
      // console.log('cls= ', cls.join(' '))
    }

    return (
      <div className={cls.join(' ')}>
        <ButtonMenuClose
          onButtonMenuCloseHandler={this.props.onButtonMenuCloseHandler}
        />
        {
          // window.localStorage.getItem('quizAuthUID') !== (null && 'ogYZTt5LF6YObyO5u3f6WiNmi8D3')
          window.localStorage.getItem('quizAuthUID') !== null && this.state.isLoggedIn
          // && window.localStorage.getItem('quizAuthExpiresIn')
            ? null
            : <div className={classes.Menu_auth_wrapper}>
              <NavLink
                to={'auth'}
                className={classes.Menu_navLink}
                onClick={this.props.onButtonMenuCloseHandler}
              >
                Войти
              </NavLink>
              <button className={classes.Menu_auth_reg}>
                <NavLink
                  to={'auth'}
                  className={classes.Menu_navLink}
                  onClick={this.props.onButtonMenuCloseHandler}
                >
                  Регистрация
                </NavLink>
              </button>
            </div>

        }
        < ul>
          {this.renderLinks()}
        </ul>
        <div className={classes.Menu_div_for_image}>
          <img className={classes.Menu_image} src='/img/man_menu.png'
               alt='man_menu' width='276' height='190'/>
        </div>
        {/*        <button
          onClick={console.log(this.props.state)}
          >
          TEST
          </button>*/}
      </div>
    )
  }
}