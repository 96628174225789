import React from 'react'
import classes from './AnswerList.module.css'
import AnswerItem from './AnswerItem/AnswerItem'
import MediaQuery from "react-responsive";

const AnswerList = props => {
  return (
    <>
      <MediaQuery minWidth={600}>
        <ul className={classes.AnswerList}>
          {props.answers.map((answer, index) => {
            return (
              <AnswerItem
                key={index}
                index={index}
                answer={answer}
                onAnswerClick={props.onAnswerClick}
                answerState={props.answerState}
              />
            )
          })}
        </ul>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <ul className={classes.AnswerList_mobile}>
          {props.answers.map((answer, index) => {
            return (
              <AnswerItem
                key={index}
                index={index}
                answer={answer}
                onAnswerClick={props.onAnswerClick}
                answerState={props.answerState}
              />
            )
          })}
        </ul>
      </MediaQuery>
    </>
  )
}

export default AnswerList