import React from 'react'
import classes from './ButtonGreen.module.css'

const ButtonGreen = props => {
  return (
    <button
      className={classes.ButtonGreen}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

export default ButtonGreen